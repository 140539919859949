<template>
    <modal
        id="delivery"
        ref="delivery"
        :title="$t('modal.addresses.heading')"
        type="user"
    >
        <form @submit.prevent="save">
            <div
                class="form-group w100"
                :class="{ error: errors.contact_label }"
            >
                <label>{{ $t('form.fields.label.contact_label') }}</label>
                <input
                    v-model="input.contact_label"
                    type="text"
                    class="form-control"
                    name="contact_label"
                    :placeholder="$t('form.fields.placeholder.addressLabel')"
                >
                <span
                    v-if="errors.contact_label"
                    class="error-message"
                >{{
                    errors.contact_label
                }}</span>
            </div>
            <div
                class="form-group w50"
                :class="{ error: errors.contact_name }"
            >
                <label>{{ $t('form.fields.label.contact_name') }}</label>
                <input
                    v-model="input.contact_name"
                    type="text"
                    class="form-control"
                    name="contact_name"
                    :placeholder="$t('form.fields.placeholder.name')"
                >
                <span
                    v-if="errors.contact_name"
                    class="error-message"
                >{{
                    errors.contact_name
                }}</span>
            </div>
            <div
                class="form-group w50"
                :class="{ error: errors.contact_phone }"
            >
                <label>{{ $t('form.fields.label.phone') }}</label>
                <input
                    v-model="input.contact_phone"
                    type="text"
                    class="form-control"
                    name="contact_phone"
                    :placeholder="$t('form.fields.placeholder.phone')"
                >
                <span
                    v-if="errors.contact_phone"
                    class="error-message"
                >{{
                    errors.contact_phone
                }}</span>
            </div>
            <div
                class="form-group w50"
                :class="{ error: errors.contact_county }"
            >
                <label>{{ $t('form.fields.label.contact_county') }}</label>
                <multi-select-field
                    :options="allCounties"
                    :placeholder="$t('form.fields.placeholder.selectCounty')"
                    :selected="selectedCounty"
                    type="county"
                    @updateSelectedOption="updateSelectedOption"
                />
            </div>
            <div
                class="form-group w50"
                :class="{ error: errors.contact_city }"
            >
                <label>{{ $t('form.fields.label.contact_city') }}</label>
                <multi-select-field
                    :options="countyBasedCities"
                    :placeholder="$t('form.fields.placeholder.selectCity')"
                    :selected="selectedCity"
                    type="city"
                    @updateSelectedOption="updateSelectedOption"
                />
            </div>
            <div
                class="form-group w60"
                :class="{ error: errors.contact_address }"
            >
                <label>{{ $t('form.fields.label.contact_address') }}</label>
                <input
                    v-model="input.contact_address"
                    type="text"
                    class="form-control"
                    name="contact_address"
                    :placeholder="$t('form.fields.placeholder.address')"
                >
                <span
                    v-if="errors.contact_address"
                    class="error-message"
                >{{
                    errors.contact_address
                }}</span>
            </div>
            <div
                class="form-group w40"
                :class="{ error: errors.contact_postcode }"
            >
                <label>{{ $t('form.fields.label.contact_postcode') }}</label>
                <input
                    v-model="input.contact_postcode"
                    type="text"
                    class="form-control"
                    name="contact_postcode"
                    :placeholder="$t('form.fields.placeholder.postcode')"
                >
                <span
                    v-if="errors.contact_postcode"
                    class="error-message"
                >{{
                    errors.contact_postcode
                }}</span>
            </div>
            <div class="actions">
                <b-button
                    variant="primary"
                    class="link"
                    @click="closeModal()"
                >
                    {{ $t('modal.general.cancelButton') }}
                </b-button>
                <b-button
                    :disabled="sending"
                    variant="primary"
                    class="fill"
                    type="submit"
                >
                    {{ actionLabel }}
                </b-button>
            </div>
        </form>
    </modal>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    import MultiSelectField from '@/components/controls/MultiSelectField';
    import { formValidations } from '@/services/formValidations';
    import { inputErrors } from '@/services/inputErrors';

    import Modal from './Modal';
    export default {
        name: 'UserDeliveryModal',
        components: {
            Modal,
            MultiSelectField,
        },
        data() {
            return {
                input: {
                    contact_label: '',
                    contact_name: '',
                    contact_phone: '',
                    contact_email: '',
                    contact_country: 'ro',
                    contact_county: '',
                    contact_city: '',
                    contact_address: '',
                    contact_postcode: '',
                    person_type: 'individual',
                    selected: true,
                },
                allCounties: [],
                countyBasedCities: [],
                selectedCounty: null,
                selectedCity: null,
                errors: {},
                toastMessage: '',
                initializeExistingData: false,
                sending: false,
                action: 'add',
            };
        },
        computed: {
            ...mapState('general', ['counties', 'cities']),
            ...mapState('user', ['inputErrors', 'modal']),
            actionLabel() {
                return this.action == 'add'
                    ? this.$t('modal.general.addButton')
                    : this.$t('modal.general.saveButton');
            },
        },
        methods: {
            ...mapActions('user', ['addData', 'updateData']),
            ...mapActions('general', ['getCounties', 'getCities']),
            async save() {
                this.errors = {};
                this.input.contact_county = this.selectedCounty
                    ? this.selectedCounty.text
                    : '';
                this.input.contact_city = this.selectedCity ? this.selectedCity.text : '';
                this.errors = formValidations(this.input);

                if (Object.keys(this.errors).length !== 0) {
                    return;
                }
                this.sending = true;
                if (this.action == 'add') {
                    await this.addData({ inputs: this.input, type: 'delivery' });
                    this.sending = false;
                    if (Object.keys(this.inputErrors).length > 0) {
                        this.errors = Object.assign({}, inputErrors(this.inputErrors));
                        return;
                    }
                    this.toastMessage = this.$t('notification.deliveryAddressAdded');
                } else {
                    await this.updateData({ inputs: this.input, type: 'delivery' });
                    this.sending = false;
                    if (Object.keys(this.inputErrors).length > 0) {
                        this.errors = Object.assign({}, inputErrors(this.inputErrors));
                        return;
                    }
                    this.toastMessage = this.$t('notification.deliveryAddressUpdated');
                }
                this.$toast.open({
                    message: this.toastMessage,
                    duration: 4000,
                    position: 'bottom-right',
                    type: 'success',
                });
                this.closeModal();
            },
            closeModal() {
                this.$refs.delivery.closeModal();
            },
            setCounties() {
                let storageCounties = JSON.parse(localStorage.getItem('counties'));
                storageCounties = Object.keys(storageCounties).map(function (key) {
                    return { value: key, text: storageCounties[key] };
                });
                this.allCounties = storageCounties;
            },
            setCountyCities() {
                let storageCounties = JSON.parse(localStorage.getItem('counties'));
                if (this.initializeExistingData) {
                    this.initializeExistingData = false;
                } else {
                    this.selectedCity = null;
                }
                if (storageCounties) {
                    let countyCode = Object.keys(storageCounties).find(
                        (key) => storageCounties[key] === this.selectedCounty.text
                    );
                    if (countyCode) {
                        let allCities = JSON.parse(localStorage.getItem('cities'))[
                            countyCode
                        ];
                        allCities = Object.keys(allCities).map(function (key) {
                            return { value: key, text: allCities[key] };
                        });

                        this.countyBasedCities = allCities;
                    }
                }
            },
            initialize() {
                let counties = localStorage.getItem('counties');
                let cities = localStorage.getItem('cities');
                this.selectedCity = null;
                if (!counties) {
                    this.getCounties();
                } else {
                    this.setCounties();
                }
                if (!cities) {
                    this.getCities();
                }
                if (Object.keys(this.modal.data.inputData).length > 0) {
                    this.input = Object.assign({}, this.modal.data.inputData);
                    this.initializeExistingData = true;
                    this.selectedCounty = {
                        text: this.input.contact_county,
                        value: this.input.contact_county,
                    };
                    this.selectedCity = {
                        text: this.input.contact_city,
                        value: this.input.contact_city,
                    };
                }
                this.action = this.modal.data.action;
            },
            updateSelectedOption(data) {
                if (data.type == 'city') {
                    this.selectedCity = data.value;
                } else {
                    this.selectedCounty = data.value;
                }
            },
        },
        mounted() {
            this.initialize();
        },
        watch: {
            counties: {
                handler() {
                    this.setCounties();
                },
                deep: true,
            },
            selectedCounty: {
                handler(value) {
                    if (value) {
                        this.setCountyCities();
                    }
                },
            },
        },
    };
</script>
